<template>
  <el-pagination
      :page-size="pageSize" :total="total"
      layout="prev, slot, next, jumper"
      prev-text="上一页"
      next-text="下一页"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
  >
    <span class="pagination-page">{{ page }}</span><span class="pagination-divide">/</span><span class="pagination-total">{{ Math.ceil(total / pageSize) }}</span>
  </el-pagination>
</template>

<script>
// 自定义分页器
export default {
  name: 'Pagination',
  props: ['pageSize', 'total', 'page'],
  methods: {
    handleSizeChange(val) {
      this.$emit('size-change', val)
    },
    handleCurrentChange(val) {
      this.$emit('current-change', val)
    }
  }
}
</script>

<style lang="scss" scoped>
.el-pagination{
  .pagination-page{
    min-width: auto;
    font-weight: 400;
    font-size: 15px;
    color: #309AF2;
    line-height: 28px;
  }
  .pagination-divide{
    min-width: auto;
    margin: 0 4px;
  }
  .pagination-divide,
  .pagination-total{
    min-width: auto;
    font-weight: 400;
    font-size: 15px;
    color: #333333;
    line-height: 28px;
  }
  ::v-deep .el-pagination__jump{
    font-size: 0;
    position: relative;
    padding-right: 44px;
    margin-left: 42px;
    &:after{
      content: '跳转';
      width: 44px;
      font-weight: 400;
      font-size: 15px;
      color: #333333;
      line-height: 21px;
      text-align: right;
      position: absolute;
      top: calc((100% - 21px) / 2);
      right: 0;
    }
  }
}
</style>
