<template>
  <article class="school-container">
    <header class="header">
      <div class="title">按学校查看</div>
      <div class="search">
        <div class="left">
          <el-select class="select" v-model="provinceId" placeholder="省" @change="provinceChange">
            <el-option v-for="item in provinceList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select class="select" v-model="cityId" placeholder="市" @change="cityChange">
            <el-option v-for="item in cityList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select class="select" v-model="countyId" placeholder="区">
            <el-option v-for="item in countyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-select class="select" v-model="gradeId" placeholder="学段">
            <el-option v-for="item in gradeList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
          <el-autocomplete
              class="input"
              v-model="searchName"
              :fetch-suggestions="querySearch"
              placeholder="请输入学校名称搜索"
              :trigger-on-focus="false"></el-autocomplete>
        </div>
        <div class="btn">
          <el-button type="primary" :disabled="loading" @click="search">查询</el-button>
        </div>
      </div>
    </header>
    <section class="table" v-loading="loading">
      <ul class="tr title">
        <li class="td name">学校名称</li>
        <li class="td city">市</li>
        <li class="td county">区</li>
        <li class="td">学段</li>
        <li class="td">
          <div class="sort-btn" @click="sort('teacherNum')">
            <div>教师数</div>
          <!--  <div-->
          <!--      :class="['icon', orderByMapAttr !== 'teacherNum' ? '' : orderByMap === 'asc' ? 'asc-icon' : 'desc-icon']"></div>-->
          </div>
        </li>
        <li class="td homeworkNum" @click="sort('homeworkNum')">
          <div class="sort-btn">
            <div>累计作业</div>
          <!--  <div-->
          <!--      :class="['icon', orderByMapAttr !== 'homeworkNum' ? '' : orderByMap === 'asc' ? 'asc-icon' : 'desc-icon']"></div>-->
          </div>
        </li>
        <li class="td createAt">最近布置</li>
<!--        <li class="td createAt" @click="sort('createAt')">-->
<!--          <div class="sort-btn">-->
<!--            <div>最近布置</div>-->
<!--            <div-->
<!--                :class="['icon', orderByMapAttr !== 'createAt' ? '' : orderByMap === 'asc' ? 'asc-icon' : 'desc-icon']"></div>-->
<!--          </div>-->
<!--        </li>-->
        <li class="td btn"></li>
      </ul>
      <div class="content">
        <div v-if="schoolList.length === 0" class="empty-content-container">
          <div class="icon-classes-none"></div>
          <p>数据默认不查询，如需查看数据，请手动点击查询按钮</p>
        </div>
        <ul v-else class="tr" v-for="(item, index) in schoolList" :key="item.schoolId">
          <li class="td name">{{ item.schoolName }}</li>
          <li class="td city">{{ item.cityName }}</li>
          <li class="td county">{{ item.areaName }}</li>
          <li class="td">{{ item.sectionName }}</li>
          <li class="td">{{ item.teacherNum }}</li>
          <li class="td homeworkNum">{{ item.homeworkNum }}</li>
          <li class="td createAt">{{ item.createAt | trimT }}</li>
          <li class="td btn">
            <div class="check-btn" @click="check(index)">查看</div>
          </li>
        </ul>
        <div class="text-right mt-20 pr-60">
          <Pagination :page-size="pageSize" :total="totalCount" :page="pageIndex" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import Pagination from '@/components/common/Pagination.vue'
export default {
  name: 'checkBySchool',
  components: {
    Pagination
  },
  data() {
    return {
      loading: false,
      provinceList: [],
      provinceId: null,
      cityList: [],
      cityId: null,
      countyList: [],
      countyId: null,
      gradeList: [{
        id: 1,
        name: '初中'
      }, {
        id: 2,
        name: '高中'
      }],
      gradeId: null,
      schoolList: [],
      searchSchoolList: [],
      pageIndex: 1,
      pageSize: 30,
      totalCount: 1,
      orderByMapAttr: '',
      orderByMap: '',
      searchName: '',
      searchId: ''
    }
  },
  watch: {
    searchName(val) {
      const {searchSchoolList} = this
      let searchId = ''
      for (let i = 0, len = searchSchoolList.length; i < len; i += 1) {
        if (val === searchSchoolList[i].schoolName) {
          searchId = searchSchoolList[i].schoolId
          break
        }
      }
      this.searchId = searchId
    }
  },
  created() {
    // this.findHomeworkListBySuperId(() => {
    //   this.getSchoolList()
    //   this.getProvince()
    // })
    this.getSchoolList()
    this.getProvince()
  },
  methods: {
    getSchoolList() {
      this.$axios({
        method: 'post',
        url: this.$urls.getSchoolList
      }).then((response) => {
        if (response.state === '11') {
          const {data} = response
          for (let i = 0, len = data.length; i < len; i += 1) {
            data[i].value = data[i].schoolName
          }
          this.searchSchoolList = data
        }
      }).catch(() => {
        this.loading = false
      })
    },
    // 获取省
    getProvince() {
      const that = this
      that.$axios({
        method: 'post',
        url: that.$urls.cityList,
        data: {
          gradeTypeId: 1
        }
      }).then((response) => {
        if (response.state === '11') {
          that.provinceList = response.data
        }
      })
    },
    // 获取市
    getCity() {
      const that = this
      that.cityId = null
      that.cityList = []
      that.countyId = null
      that.countyList = []
      that.$axios({
        method: 'post',
        url: that.$urls.cityGet,
        data: {
          pid: that.provinceId
        }
      }).then((response) => {
        if (response.state === '11') {
          that.cityList = response.data
        }
      })
    },
    // 获取区
    getCounty() {
      this.countyId = null
      this.countyList = []
      this.$axios({
        method: 'post',
        url: this.$urls.cityGet,
        data: {
          pid: this.cityId
        }
      }).then((response) => {
        if (response.state === '11') {
          this.countyList = response.data
        }
      })
    },
    provinceChange() {
      this.getCity()
    },
    cityChange() {
      this.getCounty()
    },
    querySearch(queryString, cb) {
      const {searchSchoolList} = this
      const list = searchSchoolList.filter(this.schoolFilter(queryString))
      cb(list)
    },
    schoolFilter(name) {
      return (school) => {
        return (school.schoolName.indexOf(name) !== -1)
      }
    },
    search() {
      this.pageIndex = 1
      this.findHomeworkListBySuperId()
    },
    handleSizeChange(val) {
      this.pageIndex = val
      this.findHomeworkListBySuperId()
    },
    handleCurrentChange(val) {
      this.pageIndex = val
      this.findHomeworkListBySuperId()
    },
    sort(attr) {
      // this.pageIndex = 1
      // if (this.orderByMapAttr === attr) {
      //   this.orderByMap = this.orderByMap === 'asc' ? 'desc' : 'asc'
      // } else {
      //   this.orderByMapAttr = attr
      //   this.orderByMap = 'asc'
      // }
      // this.findHomeworkListBySuperId()
    },
    findHomeworkListBySuperId(callback) {
      this.schoolList = []
      this.loading = true
      const {pageIndex} = this
      const {pageSize} = this
      const dataParam = {}
      dataParam.pageIndex = pageIndex
      dataParam.pageSize = pageSize
      const {orderByMapAttr} = this
      const {orderByMap} = this
      if (orderByMapAttr) {
        const obj = {}
        obj[orderByMapAttr] = orderByMap
        dataParam.orderByMap = obj
      }
      const {searchId} = this
      if (searchId !== '') {
        dataParam.schoolId = searchId
      } else {
        if (this.provinceId) dataParam.province = this.provinceId
        if (this.cityId) dataParam.city = this.cityId
        if (this.countyId) dataParam.county = this.countyId
        if (this.gradeId) dataParam.section = this.gradeId
      }
      this.$axios({
        method: 'post',
        url: this.$urls.findHomeworkListBySuperId,
        data: dataParam
      }).then((response) => {
        this.loading = false
        if (response.state === '11') {
          const {data} = response
          this.schoolList = data.result
          this.totalCount = data.totalCount
          if (typeof callback === 'function') callback()
        }
      }).catch(() => {
        this.loading = false
      })
    },
    check(index) {
      this.$store.commit('includeDel', 'workBySchool')
      const {schoolList} = this
      const {schoolName, schoolId, provinceId, section} = schoolList[index]
      this.$router.push({
        name: 'workBySchool',
        query: {
          id: schoolId,
          name: schoolName,
          provinceId,
          section
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
//.school-container {
//  height: calc(100vh - 84px);
//  overflow: auto;
//}

.header {
  background-color: #fff;
  padding: 0 28px;
  margin-bottom: 10px;

  .title {
    height: 84px;
    line-height: 84px;
    @include font(20px, #333);
    font-weight: bold;
  }

  .search {
    @include flex(space-between);
    height: 56px;

    .left {
      @include flex;

      .select {
        flex-shrink: 0;
        width: 140px;
        margin-right: 10px;
      }
    }

    .btn {
      padding-left: 28px;
      border-left: 1px solid #E5E5E5;

      button {
        width: 100px;
        height: 40px;
        font-size: 15px;
      }
    }

    .input {
      width: 250px;
    }
  }
}

.table {
  background-color: #fff;
  padding: 10px 28px;

  .tr {
    @include flex;
    height: 64px;
    border-bottom: 1px solid #F1F1F1;

    .td {
      flex-shrink: 0;
      width: 90px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: center;
      font-size: 17px;
    }

    .name {
      flex-grow: 1;
      padding-left: 28px;
      text-align: left;
    }

    .city,
    .county {
      width: 112px;
    }

    .homeworkNum {
      width: 100px;
    }

    .createAt {
      width: 212px;
    }

    .btn {
      width: 134px;

      .check-btn {
        width: 67px;
        height: 30px;
        line-height: 30px;
        border: 1px solid #309AF2;
        border-radius: 15px;
        margin: 0 auto;
        @include font(13px, #309AF2, center);
        cursor: pointer;

        &:hover {
          background-color: #309AF2;
          color: #fff;
        }
      }
    }

    .sort-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .icon {
        background-image: url("../../assets/sort.png");
        background-size: 100% 100%;
        width: 6px;
        height: 11px;
        margin-left: 6px;
      }

      .asc-icon {
        background-image: url("../../assets/sort-2.png");
      }

      .desc-icon {
        background-image: url("../../assets/sort-1.png");
      }
    }
  }

  .title {
    background-color: #F6F6F6;
    height: 54px;
    border: none;
    margin-bottom: 10px;
  }

}

.content {
  .empty-content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .icon-classes-none {
      width: 173px;
      height: 155px;
      background: url("../../assets/icon-classes-none.png") no-repeat;
      background-size: 100% 100%;
      margin: 30px 0;
    }
    p {
      color: #999;
      //font-size: 14px;
      margin-bottom: 20px;
    }
  }
}
</style>
