import { render, staticRenderFns } from "./checkBySchool.vue?vue&type=template&id=668b2427&scoped=true"
import script from "./checkBySchool.vue?vue&type=script&lang=js"
export * from "./checkBySchool.vue?vue&type=script&lang=js"
import style0 from "./checkBySchool.vue?vue&type=style&index=0&id=668b2427&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "668b2427",
  null
  
)

export default component.exports